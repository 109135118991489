//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, onMounted, onUnmounted } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  setup () {
    const showToTopButton = ref(false)
    const showMobileButton = ref(false)
    const showLabel = ref(false)
    const component = ref(null)

    function scrollToTop () {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    function onScroll () {
      showToTopButton.value = window.scrollY > Math.ceil(window.innerHeight / 4)
      showMobileButton.value = component.value.getBoundingClientRect().top + window.scrollY > window.innerHeight
    }

    onMounted(() => {
      onScroll()
      window.addEventListener('scroll', onScroll, { passive: true })
      window.addEventListener('resize', onScroll, { passive: true })
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll, { passive: true })
      window.removeEventListener('resize', onScroll, { passive: true })
    })

    return {
      scrollToTop,
      showToTopButton,
      showLabel,
      component,
      showMobileButton
    }
  }
})
